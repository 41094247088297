<template>
  <div class="reviewChat">
    <!-- 头部 -->
    <div class="chatTop">
      <i class="el-icon-edit-outline"></i>
      Chats
    </div>
    <!-- 底部 -->
    <div class="chatBtm">
      <div class="reqText">
        If the seller does not process the request within 7 days, the system
        will automatically cancel the request.
      </div>
      <div class="requestList">
        <!-- 商品栏 -->
        <div class="requestBox"
             v-for="(item, index) in reqList"
             :key='index'>
          <div style="display: flex; justify-content: flex-start; width: 40%">
            <!-- 商品图片 -->
            <div class="reqImg">
              <img :src="item.product_image_url"
                   alt="" />
            </div>
            <!-- 商品名称和时间 -->
            <div class="reqGoods">
              <div class="reqName">
                <span :class="item.site_class"></span>
                <span @click="freeGood(item)">{{item.product_title}}</span>
              </div>
              <div class="reqSell"><b>Seller User</b> : {{item.firstname}}  {{item.lastname}}</div>
              <div class="reqTime">{{item.create_date | formatDate}}</div>
            </div>
          </div>
          <!-- 商品卖方状态等 -->
          <div class="reqCuntent">
            <div class="reqState"><b>Status</b> : {{item.status}}</div>
            <!-- <div class="reqCoupon"><b>Coupon Code</b> : BTRW-99KFX5-AK8UA5</div> -->
          </div>
          <!-- 删除或购买商品 -->
          <div class="reqBtn">
            <span class="numberChat" v-if="item.numberId">{{item.numberId}}</span>
            <el-button type="primary"
                       @click="chatClick(item, true)"
                       class="round"
                       icon="el-icon-chat-dot-round">Chat</el-button>
          </div>
          <!-- 消息提醒 -->
          <!-- <span class="information">1</span> -->
        </div>
        <!-- 分页 -->
        <div class="block"
             v-if="reqListBool">
          <div class="blockDiv">
            <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-sizes="[10, 20, 50, 100]"
                         :page-size="currentSize"
                         layout="total,sizes, prev, pager, next, jumper"
                         :total="currentTotal">
          </el-pagination>
          </div>
        </div>
        <!-- 聊天室弹出层 -->
          <el-dialog title="chat"
                     :modal-append-to-body="false"
                     :visible.sync="chatTableVisible"
                     :close-on-click-modal="false"
                     width="600px"
                     :before-close="closeChat">
            <!-- 聊天室 -->
            <div class="chatBox">
              <!-- 聊天内容 -->
              <div class="textBox"
                   ref="textBox">
                <!-- 卖方 -->
               <div :class="chatItem.role === 'RULE_SELLER' ? 'chatLeft' : 'chatRight'"
                   v-for="(chatItem, index) in chatList"
                   :key="index">
                <div :class="chatItem.role === 'RULE_SELLER' ? 'seller' : 'buyer'">{{chatItem.firstname}} {{chatItem.lastname}}</div>
                <div :class="chatItem.role === 'RULE_SELLER' ? 'sellerText' : 'buyerText'">
                  <p v-if="chatItem.chatBool === true">{{chatItem.content}}</p>
                  <img v-else
                       :src="chatItem.content"
                       alt=""
                       style="max-height: 400px; max-width: 400px">
                  <p class="time" style="color: white">{{chatItem.create_date | formatDate}}</p>
                </div>
              </div>
              </div>
              <div class="sendText">
                <textarea name=""
                          id=""
                          cols="60"
                          rows="8"
                          v-model="chatTest.chatInpout"></textarea>
                <!-- <i class="el-icon-picture-outline"></i> -->
                <el-upload class="upload-demo"
                         action="https://crediscounts.com/photograph/picture"
                         :headers="chatToken"
                         :on-success="handlePreview"
                         :on-remove="handleRemove"
                         :before-upload="beforeAvatarUpload"
                         list-type="picture"
                         ref='upload'>
                <i class="el-icon-picture-outline"></i>
                <div slot="tip"
                     class="el-upload__tip">Can only upload pictures, and no more than 3MB</div>
              </el-upload>
              </div>
              <div class="send">
                <el-button @click="closeChat" type="info">Close</el-button>
                <el-button type="primary"
                           @click="sendChat()" :loading="$store.state.loadingBool">Send</el-button>
              </div>
            </div>
          </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
// 引入接口
import {
  allChat, // 所有未读信息
  count, // 用户未读消息
  chatGet, // 用户获取聊天信息
  chat, // 用户发送聊天消息
  findNotificationCounts // 状态订单数量
} from '../../api/buyerAfter'
import {
  getToken
} from '../../utils/token'
// 格式化时间
import { formatDate } from '../../utils/data.js'
export default {
  data () {
    return {
      // 消息弹出层
      chatTableVisible: false,
      // 分页
      currentPage: 1,
      currentSize: 10,
      currentTotal: 0,
      // 商品栏
      reqList: [],
      reqListBool: false,
      // 上传图片列表
      // 聊天室上传图片列表
      chatToken: '',
      // 消息列表
      chatList: [],
      // 消息内容
      // chatInpout: '',
      // 计时器
      timer: '',
      chatTest: {
        fileList: [],
        chatInpout: ''
      }
    }
  },
  filters: {
    // 格式化时间
    formatDate (time) {
      const date = new Date(time)
      // // (new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    }
  },
  created () {
    this.$store.commit('getLoading', false)
    this.$store.commit('getCount')
    this.chatToken = { Authorization: getToken() }
    this.getAllChat(this.currentPage, this.currentSize)
    findNotificationCounts().then(result => {
    }).catch(err => {
      return err
    })
  },
  methods: {
    // 获取未读消息
    getAllChat (page, size) {
      this.currentSize = size
      // console.log('aaaa')
      // 所有未读信息
      allChat({
        page: page,
        size: size
      }).then(result => {
        if (result.data.code === 200) {
          // console.log(result)
          const testList = []
          this.currentTotal = result.data.data.total
          if (result.data.data.rows.length > 0) {
            result.data.data.rows.map(v => {
              for (const key in this.$store.state.freeStatusList) {
                if (v.status === parseInt(this.$store.state.freeStatusList[key])) {
                  v.status = key
                }
              }
              count({
                freeRequestId: v.id
              }).then(result => {
                if (result.data.code === 200) {
                  for (var key in v) {
                    // (key)
                    if (result.data.data !== 0) {
                      v.numberId = result.data.data
                    }
                  }
                  v.img = []
                  v.img.push(v.product_image_url)
                  testList.push(v)
                }
                this.reqList = testList
                // 是否显示分页
                if (this.reqList.length > 0) {
                  this.reqListBool = true
                } else {
                  this.reqListBool = false
                }
              }).catch(err => {
                return err
              })
            })
          } else {
            this.reqList = []
            // 是否显示分页
            if (this.reqList.length > 0) {
              this.reqListBool = true
            } else {
              this.reqListBool = false
            }
          }
        }
        // ('result =>', result)
      }).catch(err => {
        return err
      })
    },
    // 聊天室弹出层控制
    closeChat () {
      this.chatTableVisible = false
      this.getAllChat(this.currentPage, this.currentSize)
      this.$store.commit('getCount')
    },
    // 聊天室
    chatClick (item, boolean) {
      if (boolean) {
        this.reqList.map(v => {
          if (item.id === v.id) {
            delete v.numberId
          }
        })
        this.chatTableVisible = true
      }
      localStorage.setItem('buyer-item', JSON.stringify(item))
      let chatNum = 1
      this.timer = setInterval(() => {
        if (chatNum === 0) {
          clearInterval(this.timer)
        } else {
          chatNum--
          // 用户获取聊天信息
          chatGet({
            freeRequestId: item.id
          }).then(result => {
            if (result.data.code === 200) {
              result.data.data.rows.map(v => {
                if (v.content.indexOf('photograph') < 0) {
                  v.chatBool = true
                } else {
                  v.chatBool = false
                }
              })
              this.chatList = result.data.data.rows
              this.$nextTick(() => {
                const div = document.getElementsByClassName('textBox')[0]
                div.scrollTop = div.scrollHeight
              })
            }
            // ('resultChat =>', result)
          }).catch(err => {
            return err
          })
        }
      }, 10)
    },
    // 图片删除
    handleRemove (file, fileList) {
      this.chatTest.fileList = []
      fileList.map(v => {
        this.chatTest.fileList.push(`https://crediscounts.com/photograph/images/${v.response.message}`)
      })
    },
    // 图片上传成功
    handlePreview (file, fileList) {
      this.chatTest.fileList.push(`https://crediscounts.com/photograph/images/${fileList.response.message}`)
    },
    // 图片上传之前
    beforeAvatarUpload (file) {
      // (this.chatTest.fileList)
      const srcSize = file.size < (3072 * 3072)
      const srcReg = /image/g
      const srcBool = srcReg.test(file.type)
      if (!srcBool) {
        this.$message.error('Please upload the address')
        return false
      } else {
        if (!srcSize) {
          this.$message.error('The image size is less than 3MB')
          return false
        } else if (this.chatTest.fileList.length > 2) {
          this.$message.error('Send up to three pictures at a time')
          return false
        }
      }
    },
    // 分页
    handleSizeChange (val) {
      // (`每页 ${val} 条`)
      this.getAllChat(this.currentPage, val)
    },
    handleCurrentChange (val) {
      this.getAllChat(val, this.currentSize)
    },
    // 发送消息
    sendChat () {
      const item = JSON.parse(localStorage.getItem('buyer-item'))
      for (const key in this.chatTest) {
        if (key === 'chatInpout') {
          if (this.chatTest.chatInpout === '' && this.chatTest.fileList.length === 0) {
            this.$message.error('Cannot send empty message')
          } else {
            this.$store.commit('getLoading', true)
            this.sendChattest(item, this.chatTest.chatInpout)
          }
        } else if (key === 'fileList') {
          this.$store.commit('getLoading', true)
          this.chatTest.fileList.map(v => {
            this.sendChattest(item, v)
          })
        }
      }
    },
    // 发送消息
    sendChattest (item, v) {
      // 用户发送聊天消息
      chat({
        addVO: {
          content: `${v}`,
          free_request_id: item.id
        }
      }).then(result => {
        // ('result ==>', result)
        if (result.data.code === 200) {
          this.$store.commit('getLoading', false)
          localStorage.removeItem('buyer-item')
          this.chatTest.chatInpout = ''
          this.chatClick(item, false)
          this.$refs.upload.clearFiles()
          clearInterval(this.timer)
        }
      }).catch(err => {
        return err
      })
    },
    // 进入赠品页面
    freeGood (item) {
      this.$router.push({ path: '/F_freeDetails', query: { goodUrl: item.url, goodId: item.product_id } })
    }
  }
}
</script>

<style lang="less" scoped>
.reviewChat {
  .chatTop {
    padding: 12px 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c8ced3;
    line-height: 1.5;
    .el-icon-edit-outline {
      font-size: 14px;
      margin-right: 20px;
    }
  }
  .chatBtm {
    min-height: 100px;
    background-color: white;
    padding: 20px;
    .reqText {
      padding: 12px 20px;
      width: 45%;
      font-weight: 700;
      color: #333;
      background-color: #fff3cd;
      border: 1px solid #fff3cd;
      border-radius: 5px;
      line-height: 25px;
    }
    //底部商品栏
    .requestList {
      background-color: white;
      padding-top: 20px;
      // 商品栏
      .requestBox {
        width: 100%;
        box-sizing: border-box;
        padding: 10px 20px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        // 商品图片
        .reqImg {
          img {
            max-height: 100px;
            max-width: 100px;
          }
        }
        // 商品名称和时间
        .reqGoods {
          width: calc(100% - 130px);
          margin-left: 30px;
          // margin-right: 250px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 100px;
          font-size: 14px;
          .reqName {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            span {
              line-height: 2;
              &:nth-child(2) {
                width: calc(100% - 24px);
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-left: 10px;
                cursor: pointer;
                color: #20a8d8;
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .reqTime {
            line-height: 2;
            text-align: left;
          }
          .reqSell {
            width: 95%;
            line-height: 2;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
        }
        // 商品卖方状态等
        .reqCuntent {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: flex-end;
          min-height: 100px;
          font-size: 14px;
          padding-bottom: 15px;
          //margin-right: 100px;
          .succ {
            width: 50%;
          }
          .reqSell {
            width: 95%;
            line-height: 2;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
          div {
            line-height: 2;
          }
        }
        // 删除或购买商品
        .reqBtn {
          width: 28%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          position: relative;
          .numberChat {
            position: absolute;
            right: -10px;
            top: -10px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #f86c6b;
            color: white;
            text-align: center;
            line-height: 20px;
          }
          /deep/ .el-button {
            padding: 5px 10px;
            /deep/ i {
              font-size: 16px;
            }
          }
          /deep/ .el-button--primary.is-plain {
            color: white;
          }
          .success {
            background-color: #4dbd74;
            border-color: #4dbd74;
          }
          .error {
            background-color: #f86c6b;
            border-color: #f86c6b;
          }
          .round {
            background-color: #20a8d8;
            border-color: #20a8d8;
          }
          .share {
            display: block;
            margin-left: 10px;
            font-size: 14px;
            padding: 7px 10px;
            border-radius: 5px;
            background-color: #2f353a;
            border-color: #2f353a;
            color: white;
          }
        }
        // 消息提醒
        .information {
          position: absolute;
          right: -10px;
          top: -10px;
          // display: none;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          color: white;
          background-color: tomato;
          text-align: center;
          line-height: 20px;
          font-weight: bolder;
        }
      }
    }
  }
}
</style>
